module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Giftcard","short_name":"giftcard","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b640d46e7fe35b6117ce5eac6287d72"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es","ru","ar"],"defaultLanguage":"en","siteUrl":"https://giftcard.dagcoin.org/","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/ignored-page","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
